import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import usePatient from '@/hooks/usePatient';
import Loading from '../Loading';
import { Button } from '../ui/button';
import MedicationList from './MedicationList';
import OrderHistory from './OrderHistory';
import PatientDetails from './PatientDetails';

const PatientProfile: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data: patient, isLoading, error } = usePatient(patientId as string);
  const [activeTab, setActiveTab] = useState<'orders' | 'medications'>('orders');

  if (isLoading) {
    return <Loading />;
  }

  if (error || !patient) {
    return <div>Error loading patient data</div>;
  }

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="w-full md:w-1/3">
        <PatientDetails patient={patient} />
      </div>
      <div className="w-full md:w-2/3">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <Button variant={activeTab === 'orders' ? 'default' : 'outline'} onClick={() => setActiveTab('orders')}>
              Order History
            </Button>
            <Button
              variant={activeTab === 'medications' ? 'default' : 'outline'}
              onClick={() => setActiveTab('medications')}
            >
              Medications
            </Button>
          </div>
        </div>
        {activeTab === 'orders' ? <OrderHistory /> : <MedicationList patient={patient} />}
      </div>
    </div>
  );
};

export default PatientProfile;
