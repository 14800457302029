import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { IAccountPatient, IMedication } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { Button } from '../ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

interface MedicationListProps {
  patient: IAccountPatient;
}

const MedicationList: React.FC<MedicationListProps> = ({ patient }) => {
  const medicationModal = useModal('medication-modal');
  const navigate = useNavigate();
  const medications = patient.user.medications || [];

  const handleEdit = (medication: IMedication) => {
    medicationModal.show({
      medication: medication,
      medications: medications,
      userId: patient.user.id,
    });
  };

  const handleAddMedication = () => {
    medicationModal.show({
      medications: medications,
      userId: patient.user.id,
    });
  };

  const handleStartOrder = async (medication: IMedication) => {
    try {
      const response = await axiosClient.post('/v1/requests', {
        medication_id: medication.id,
        user_id: patient.user.id,
        fresh: true,
      });
      if (response.data && response.data.id) {
        navigate(`/requests/${response.data.id}`);
      } else {
        console.error('No request ID returned from the API');
        navigate('/requests');
      }
    } catch (error) {
      console.error('Error creating new request:', error);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Medications</h2>
        <Button onClick={handleAddMedication}>Add Medication</Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Medication</TableHead>
            <TableHead>Dosage</TableHead>
            <TableHead>Form</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {medications.length > 0 ? (
            medications.map((medication: IMedication) => (
              <TableRow key={medication.id}>
                <TableCell>{medication.medication}</TableCell>
                <TableCell>{medication.dosage}</TableCell>
                <TableCell>{medication.dosage_form}</TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button onClick={() => handleEdit(medication)} variant="outline" size="sm">
                      Edit
                    </Button>
                    <Button onClick={() => handleStartOrder(medication)} size="sm">
                      Start Order
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                No medications found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default MedicationList;
