import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '@/components/Loading';
import usePatient from '@/hooks/usePatient';
import { IRequest } from '@/types';
import { StatusIndicator } from '../Requests/StatusIndicator';
import Time from '../Time';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

const OrderHistory: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data: patient, isLoading } = usePatient(patientId as string);

  if (isLoading) {
    return <Loading />;
  }

  if (!patient) {
    return <div>Error loading data</div>;
  }

  const requests = patient.user.requests || [];

  const sortedRequests = [...requests].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Processing Time</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedRequests.length > 0 ? (
          sortedRequests.map((request: IRequest) => (
            <TableRow key={request.id}>
              <TableCell>
                <Time>{request.created_at}</Time>
              </TableCell>
              <TableCell>
                <StatusIndicator status={request.status} isOrderLevel={true} />
              </TableCell>
              <TableCell>{request.processing_time}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} className="text-center">
              No orders found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderHistory;
