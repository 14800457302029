import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Drawer from '@/components/Modals/Drawer';
import { ScrollArea } from '../ui/scroll-area';
import NewPatientForm from './NewPatientForm';

const NewPatientDrawer = NiceModal.create(() => {
  const modal = useModal('new-patient-drawer');

  return (
    <Drawer modal={modal} title="New Patient Intake">
      <ScrollArea className="px-4 py-8">
        <NewPatientForm />
      </ScrollArea>
    </Drawer>
  );
});

export default NewPatientDrawer;
