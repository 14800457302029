/* eslint-disable */
// @ts-nocheck
import { useState, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table';
import usePharmacyAnalytics from '@/hooks/usePharmacyAnalytics';
import { IPharmacyAnalytic } from '@/types';
import Loading from '../Loading';
import Section from '../Section';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import PharmacyMedicationAvailability from './PharmacyMedicationAvailability';

const PharmacyAnalytics = () => {
  const analytics = usePharmacyAnalytics();
  const [pharmacy, setPharmacy] = useState<IPharmacyAnalytic | null>(null);

  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      {
        header: 'Pharmacy',
        accessorKey: 'name',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'Address',
        accessorFn: (row: IPharmacyAnalytic) => `${row.address1}`,
        id: 'address',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'City',
        accessorFn: (row: IPharmacyAnalytic) => row.city,
        id: 'city',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'State',
        accessorFn: (row: IPharmacyAnalytic) => row.state,
        id: 'state',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'Zip',
        accessorFn: (row: IPharmacyAnalytic) => row.zip,
        id: 'zip',
        cell: (info: any) => info.getValue(),
      },
      {
        header: 'In Stock',
        accessorFn: (row: IPharmacyAnalytic) => `${row.total_in_stock}/${row.total_tasks}`,
        id: 'inStock',
        cell: (info: any) => info.getValue(),
      },
    ],
    [],
  );

  const data = useMemo(() => (analytics.data ? analytics.data : []), [analytics.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'auto',
  });

  return (
    <div>
      <div className="flex gap-8">
        <div className="flex w-full">
          <Section title={'Pharmacy Overview'}>
            <div className="mb-2">
              <Input
                value={globalFilter ?? ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search pharmacies..."
              />
            </div>
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[(header.column.getIsSorted() as 'asc' | 'desc') ?? ''] ?? null}
                          </div>
                        )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {analytics.isLoading || !analytics.data ? (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      onClick={() => setPharmacy(row.original)}
                      className={pharmacy && pharmacy.id === row.original.id && 'bg-purple-100 hover:bg-purple-150'}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>No data.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className="flex items-center gap-2 mt-2">
              <Button variant="outline" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                {'<'}
              </Button>
              <Button variant="outline" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                {'>'}
              </Button>
              <span>
                Page{' '}
                <strong>
                  {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </strong>
              </span>
              <span>
                | Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    table.setPageIndex(page);
                  }}
                  className="w-16 p-1 border rounded"
                />
              </span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="p-1 border rounded"
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </Section>
        </div>
        <div className="flex w-1/2">
          <Section title={pharmacy ? `Medication Availability for ${pharmacy.name}` : 'Medication Availability'}>
            {pharmacy ? (
              <PharmacyMedicationAvailability pharmacy={pharmacy} />
            ) : (
              <p>Select a pharmacy to view medication availability</p>
            )}
          </Section>
        </div>
      </div>
    </div>
  );
};

export default PharmacyAnalytics;
