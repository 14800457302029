import { useMemo } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useAnalytics from '@/hooks/useAnalytics';
import { IAnalytic } from '@/types';
import Section from '../Section';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import PharmacyAnalytics from './PharmacyAnalytics';

interface IPharmacyCardProps {
  medication: string;
  items: IAnalytic[];
}

const MedicationCard = ({ medication, items }: IPharmacyCardProps) => {
  return (
    <Section title={medication}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Variant</TableHead>
            <TableHead>Dosage</TableHead>
            <TableHead>Preference</TableHead>
            <TableHead>Total Contacts</TableHead>
            <TableHead>In Stock Rate</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.variant}</TableCell>
              <TableCell>{item.dosage_original}</TableCell>
              <TableCell>{item.preference}</TableCell>
              <TableCell>{Number(item.total_contacts)}</TableCell>
              <TableCell>{item.in_stock_rate}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};

const AvailabilityTool = () => {
  const analytics = useAnalytics();

  const groupedSortedMedications = useMemo(() => {
    if (!analytics.data) return [];

    return analytics.data.reduce((acc: any, medication: IAnalytic) => {
      if (!acc[medication.medication]) {
        acc[medication.medication] = [];
      }
      acc[medication.medication].push(medication);
      return acc;
    }, {});
  }, [analytics.data]);

  return (
    <div className="p-4">
      <h1 className="mb-2 text-3xl font-bold">Live insights on your area</h1>
      <p className="mb-6 text-muted-foreground">
        Detailed view of medication shortages, pharmacy stock levels, and area health for prescribers.
      </p>

      {analytics.isLoading || analytics.data === null ? (
        <div className="flex items-center justify-center">
          <p>Loading...</p>
        </div>
      ) : (
        <Tabs defaultValue="medications" className="mb-6">
          <TabsList>
            <TabsTrigger value="medications">Medications</TabsTrigger>
            <TabsTrigger value="pharmacies">Pharmacies</TabsTrigger>
          </TabsList>
          <TabsContent value="medications">
            <div className="grid grid-cols-1 gap-6">
              {Object.keys(groupedSortedMedications).map((medication, index) => (
                <MedicationCard key={index} medication={medication} items={groupedSortedMedications[medication]} />
              ))}
            </div>
          </TabsContent>
          <TabsContent value="pharmacies">
            <PharmacyAnalytics />
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
};
export default AvailabilityTool;
