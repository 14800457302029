import Login from '@/components/External/Login.tsx';
import MFA from '@/components/External/MFA.tsx';
import AvailabilityTool from './components/AvailabilityTool/AvailabilityTool.tsx';
import Logout from './components/External/Logout.tsx';
import ExternalWrapper from './components/ExternalWrapper.tsx';
import PatientProfile from './components/Patients/PatientProfile.tsx';
import Patients from './components/Patients/Patients.tsx';
import Pharmacies from './components/Pharmacies/Pharmacies.tsx';
import PharmacyGroup from './components/Pharmacies/PharmacyGroup.tsx';
import RequestLoader from './components/Requests/RequestLoader.tsx';
import Settings from './components/Settings/Settings.tsx';
import Staff from './components/Staff/Staff.tsx';
import Application from './pages/Application';

const routes = [
  {
    url: '/',
    element: <Application />,
    private: false,
  },
  {
    url: '/login',
    exact: true,
    element: (
      <ExternalWrapper>
        <Login />
      </ExternalWrapper>
    ),
    private: false,
  },
  {
    url: '/requests/:requestId',
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/mfa/:method/:value/:token',
    exact: true,
    element: (
      <ExternalWrapper>
        <MFA />
      </ExternalWrapper>
    ),
    private: false,
  },
  {
    url: '/logout',
    exact: true,
    element: <Logout />,
    private: true,
  },
  {
    url: '/settings',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/patients/:patientId',
    exact: true,
    element: <PatientProfile />,
    private: true,
  },
  {
    url: '/patients',
    exact: true,
    element: <Patients />,
    private: true,
  },
  {
    url: '/staff',
    exact: true,
    element: <Staff />,
    private: true,
  },

  {
    url: '/availabity-tool',
    exact: true,
    element: <AvailabilityTool />,
    private: true,
  },
  {
    url: '/pharmacies',
    element: <Pharmacies />,
    private: true,
  },
  {
    url: '/pharmacy-groups/:groupId',
    element: <PharmacyGroup />,
    private: true,
  },
];

export { routes };
