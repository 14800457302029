import { IDashboardStats } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useDashboard() {
  const query = getUrl(`v2/providers/dashboard`);
  return {
    ...query,
    data: query.data as IDashboardStats,
  };
}

export default useDashboard;
