import React from 'react';
import { IAccountPatient, IRequest } from '@/types';
import LocationMap from '../Location/LocationMap';

interface PatientDetailsProps {
  patient: IAccountPatient;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ patient }) => {
  const dummyRequest: IRequest = {
    id: '',
    medications: [],
    status: '',
    created_at: '',
    updated_at: '',
    user_id: patient.user.id,
    user: patient.user,
    total_tickets: 0,
    tasks: [],
    pharmacies: [],
    radius: 40000,
    request_tickets: [],
    processing_time: '',
    processing_time_paid: false,
    needle_note: '',
    status_details: { title: '', text: '', status: '' },
    location: patient.user.locations?.[0],
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">{patient.user.name}</h2>
      <div className="space-y-2">
        <p>
          <strong>Email:</strong> {patient.user.email}
        </p>
        <p>
          <strong>Phone:</strong> {patient.user.phone}
        </p>
        <p>
          <strong>DOB:</strong> {patient.user.dob}
        </p>
        <p>
          <strong>Address:</strong> {patient.user.locations?.[0]?.address}
        </p>
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Nearby Pharmacies</h3>
        <LocationMap request={dummyRequest} />
      </div>
    </div>
  );
};

export default PatientDetails;
