import NiceModal from '@ebay/nice-modal-react';
import LocationModal from '@/components/Location/LocationInput.tsx';
import MedicationModal from '@/components/Modals/Medications/MedicationModal.tsx';
import MedicationPhotoModal from '@/components/Modals/Medications/MedicationPhotoModal.tsx';
import NewPatientDrawer from '../Patients/NewPatientDrawer';
import NewGroupDrawer from '../Pharmacies/NewGroupDrawer';
import EditStaffDrawer from '../Staff/EditStaffDrawer';
import NewStaffDrawer from '../Staff/NewStaffDrawer';
import MedicationResultsModal from './MedicationResults/MedicationResultsModal';
import MembershipModal from './Memberships/MembershipModal';

NiceModal.register('medication-modal', MedicationModal);
NiceModal.register('medication-photo-modal', MedicationPhotoModal);
NiceModal.register('location-modal', LocationModal);
NiceModal.register('medication-results-modal', MedicationResultsModal);
NiceModal.register('membership-modal', MembershipModal);
NiceModal.register('new-patient-drawer', NewPatientDrawer);
NiceModal.register('new-staff-drawer', NewStaffDrawer);
NiceModal.register('edit-staff-drawer', EditStaffDrawer);
NiceModal.register('new-group-drawer', NewGroupDrawer);

const ModalRegistration = () => {
  return <></>;
};

export default ModalRegistration;
