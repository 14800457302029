import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import useDashboard from '@/hooks/useDashboard';
import Loading from '../Loading';

const Dashboard = () => {
  const dashboard = useDashboard();

  if (dashboard.isLoading || !dashboard.data) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Dashboard</div>
      </div>

      <div className="flex flex-col-reverse justify-between gap-8 mb-8 sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">My Dashboard</div>
          <div className="mt-1 text-sm font-normal leading-normal text-gray-500 text-pretty">
            Welcome to Needle, here you can view the total number of requests, patients, and providers.
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-2 p-4 text-xl text-center border border-gray-300 rounded-md">
          <div className="">{dashboard.data?.total_requests}</div>
          <div className="">Requests</div>
        </div>
        <div className="flex flex-col gap-2 p-4 text-xl text-center border border-gray-300 rounded-md">
          <div className="">{dashboard.data?.total_patients}</div>
          <div className="">Patients</div>
        </div>
        <div className="flex flex-col gap-2 p-4 text-xl text-center border border-gray-300 rounded-md">
          <div className="">{dashboard.data?.total_providers}</div>
          <div className="">Staff Users</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
