import { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import useAccountUsers from '@/hooks/useAccountUsers';
import axiosClient from '@/utils/axiosClient';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

const NewPatientForm = () => {
  const modal = useModal('new-patient-drawer');
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const accountStaff = useAccountUsers();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [accountUserId, setAccountUserId] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [sendOnboardingLink, setSendOnboardingLink] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clean_phone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  useEffect(() => {
    if (searchParams.has('firstName')) setFirstName(searchParams.get('firstName') || '');
    if (searchParams.has('lastName')) setLastName(searchParams.get('lastName') || '');
    if (searchParams.has('email')) setEmail(searchParams.get('email') || '');
    if (searchParams.has('dob')) setDob(searchParams.get('dob') || '');
    if (searchParams.has('phone')) setPhone(searchParams.get('phone') || '');
    if (searchParams.has('zipCode')) setZipCode(searchParams.get('zipCode') || '');
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axiosClient.post('/v2/patients', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: dob,
        phone: clean_phone(phone),
        zip: zipCode,
        account_user_id: accountUserId,
        onboarding_method: sendOnboardingLink ? 'sms' : 'email',
      });

      if (response.data && response.data.id) {
        posthog.capture('provider_patient_intake');
        modal.hide();
        navigate(`/patients/${response.data.account_patient_id}`);
      } else {
        navigate('/patients');
      }
    } catch (error) {
      console.error('Error saving patient:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const prescribers = accountStaff?.data?.filter((staff) => staff.is_prescriber) || [];

  return (
    <div>
      <div className="p-4 mb-8 bg-blue-100 rounded-md">
        <div className="font-semibold">To make patient intake easier we support two options:</div>
        <div className="mt-2 ml-2 text-sm">
          1. You can create and manage the entire process without the patients needing to do anything. You will have to
          select and manage the pharmacies we will be calling for them.
        </div>
        <div className="mt-2 ml-2 text-sm">
          2. You will enter basic information about the patient and medications they are being prescribed and we will
          text them a link for them to select and manage their own pharmacies.
        </div>
      </div>

      <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
        <div className="gap-4 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
          <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">First Name</Label>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                required
                autoFocus
              />
            </div>
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">Last Name</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-row items-start self-stretch w-full gap-8">
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Email Address</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full">
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-1 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                placeholder="Email Address"
              />
            </div>
          </div>

          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Zip Code</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full">
              <Input
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="flex-1 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                placeholder="Zip Code"
                maxLength={5}
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-row items-start self-stretch w-full gap-8">
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Phone Number</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full hover:">
              <div className="relative flex items-center flex-1 gap-2 grow">
                <span className="text-base text-gray-500">+1</span>
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="flex-1 pl-2 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                  placeholder="555444433333"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Date of birth</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full">
              <Input
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="flex-1 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                placeholder="MM/DD/YYYY"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-row items-start self-stretch w-full gap-8">
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Assigned Prescriber</Label>
            <Select
              onValueChange={(staffId) => {
                setAccountUserId(staffId);
              }}
              value={accountUserId}
            >
              <SelectTrigger>
                <SelectValue placeholder={`Assign patient to prescriber`} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">Assign patient to prescriber</SelectItem>
                {prescribers.map((staff: any) => (
                  <SelectItem key={staff.user.name} value={staff.id}>
                    {staff.user.name} ({staff.role})
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div
          className="flex flex-col items-start gap-4 cursor-pointer"
          onClick={() => setSendOnboardingLink(!sendOnboardingLink)}
        >
          <div className="inline-flex items-center gap-2 relative flex-[0_0_auto]">
            <Switch checked={sendOnboardingLink} />
            <p className="relative w-fit mt-[-1.00px] text-sm font-medium text-gray-700 whitespace-nowrap">
              Send this patient a custom onboarding link via SMS
            </p>
          </div>
          <p className="text-sm text-gray-600 ml-14">
            Patient will receive a link to complete their profile and select their pharmacies they wish to be called.
          </p>
        </div>

        <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
          <Button onClick={handleSubmit} disabled={isSubmitting} className="w-full sm:w-fit">
            {isSubmitting ? 'Saving patient...' : 'Create Patient'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPatientForm;
