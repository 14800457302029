import { IAccountPharmacyGroup } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccountPharmacyGroups() {
  const query = getUrl(`v2/account_pharmacy_groups`);
  return {
    ...query,
    data: query.data as IAccountPharmacyGroup[],
  };
}

export default useAccountPharmacyGroups;
