import React, { useState, useEffect, useCallback } from 'react';
import { Plus, Search, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import usePharmacyManager from '@/hooks/usePharmacyManager';
import { IAccountPharmacyGroup, IPharmacy } from '@/types';
import PharmacyIcon from '../PharmacyIcon';
import Section from '../Section';
import { Switch } from '../ui/switch';
import PharmacyMap from './PharmacyMap';

interface PharmacyGrouperProps {
  pharmacyGroup: IAccountPharmacyGroup;
  onUpdate?: (updatedGroup: IPharmacy) => void;
}

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const PharmacyGrouper: React.FC<PharmacyGrouperProps> = ({ pharmacyGroup, onUpdate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedZipCode, setSelectedZipCode] = useState('');

  const [selectedPharmacies, setSelectedPharmacies] = useState<IPharmacy[]>(pharmacyGroup.pharmacies || []);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [jumpToPage, setJumpToPage] = useState('');
  const [tempItemsPerPage, setTempItemsPerPage] = useState(itemsPerPage.toString());

  const pharmacyManager = usePharmacyManager({
    searchTerm,
    selectedState,
    selectedZipCode,
    currentPage,
    itemsPerPage,
  });

  // Sync local state with props if pharmacyGroup changes
  useEffect(() => {
    setSelectedPharmacies(pharmacyGroup.pharmacies || []);
  }, [pharmacyGroup]);

  // Update total pages when data changes
  useEffect(() => {
    if (pharmacyManager?.data?.totalPages) {
      setTotalPages(pharmacyManager?.data?.totalPages);
    }
  }, [pharmacyManager]);

  // Set of selected pharmacy IDs for efficient lookup
  const selectedPharmacyIds = new Set(selectedPharmacies.map((p) => p.id));

  const togglePharmacy = useCallback(
    (pharmacy: IPharmacy) => {
      setSelectedPharmacies((prev) => {
        const isSelected = prev.some((p) => p.id === pharmacy.id);
        let updated;

        if (isSelected) {
          updated = prev.filter((p) => p.id !== pharmacy.id); // Remove pharmacy
        } else {
          updated = [...prev, pharmacy]; // Add pharmacy
        }

        if (onUpdate) {
          onUpdate(pharmacy);
        }
        return updated;
      });
    },
    [onUpdate],
  );

  // Handler to clear all filters
  const handleClearFilters = useCallback(() => {
    setSearchTerm('');
    setSelectedState('');
    setSelectedZipCode('');
    setCurrentPage(1);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempItemsPerPage(e.target.value);
  };

  const handleItemsPerPageSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const number = parseInt(tempItemsPerPage, 10);
    if (!isNaN(number) && number > 0) {
      setItemsPerPage(number);
      setCurrentPage(1);
    }
  };

  const displayPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pharmacies = pharmacyManager?.data?.pharmacies || [];

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Search Pharmacies</h2>
        <div className="flex flex-wrap gap-4">
          <div className="flex-1 min-w-[200px]">
            <Label htmlFor="searchTerm">Search</Label>
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                id="searchTerm"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search by name or location"
                className="pl-8"
              />
            </div>
          </div>

          <div className="flex-1 min-w-[200px]">
            <Label htmlFor="stateFilter">Filter by State</Label>
            <Select
              value={selectedState}
              onValueChange={(value) => {
                setSelectedState(value);
                setCurrentPage(1);
              }}
            >
              <SelectTrigger id="stateFilter">
                <SelectValue placeholder="Select a state" />
              </SelectTrigger>
              <SelectContent className="h-52">
                <SelectItem value="">All States</SelectItem>
                {states.map((state) => (
                  <SelectItem key={state} value={state}>
                    {state}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex-1 min-w-[200px]">
            <Label htmlFor="zipCodeFilter">Filter by ZIP Code</Label>
            <Input
              id="zipCodeFilter"
              value={selectedZipCode}
              onChange={(e) => {
                setSelectedZipCode(e.target.value);
                setCurrentPage(1);
              }}
              placeholder="Enter ZIP code"
            />
          </div>

          {/* Clear Filters Button */}
          <div className="flex items-end">
            <Button onClick={handleClearFilters} variant="outline" className="space-x-2">
              <X className="w-4 h-4" />
              <span>Clear Filters</span>
            </Button>
          </div>
        </div>
      </div>

      <PharmacyMap pharmacies={pharmacies} />

      <div className="flex flex-col w-full gap-10 mt-10 lg:flex-row">
        <Section className="flex-1">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">Pharmacies</h2>
            <div className="text-sm text-muted-foreground">
              Showing {pharmacies.length} of {pharmacyManager?.data?.totalCount} pharmacies
            </div>
          </div>
          <div className="overflow-x-auto border rounded-md">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>State</TableHead>
                  <TableHead>Zip</TableHead>
                  <TableHead className="w-12"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pharmacies.map((pharmacy) => (
                  <TableRow key={pharmacy.id}>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <PharmacyIcon pharmacy={pharmacy} />
                        {pharmacy.name}
                      </div>
                    </TableCell>
                    <TableCell>{pharmacy.address1}</TableCell>
                    <TableCell>{pharmacy.state}</TableCell>
                    <TableCell>{pharmacy.zip}</TableCell>
                    <TableCell>
                      <Switch
                        onClick={() => togglePharmacy(pharmacy)}
                        checked={selectedPharmacyIds.has(pharmacy.id)}
                        aria-label={`Select ${pharmacy.name}`}
                      >
                        <Plus className="w-4 h-4" />
                      </Switch>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          {/* Pagination Controls */}
          <div className="flex items-center justify-between mt-4">
            <form onSubmit={handleItemsPerPageSubmit} className="flex items-center">
              <label className="mr-2">Items per page:</label>
              <Input
                type="number"
                value={tempItemsPerPage}
                onChange={handleItemsPerPageChange}
                className="w-20"
                min="1"
              />
            </form>

            <div className="flex items-center">
              {displayPageNumbers().map((page, index) =>
                typeof page === 'string' ? (
                  <span key={index} className="mx-2">
                    {page}
                  </span>
                ) : (
                  <Button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    variant={page === currentPage ? 'default' : 'outline'}
                    className="mx-1"
                  >
                    {page}
                  </Button>
                ),
              )}
              <Input
                type="text"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                placeholder="Jump to page"
                className="w-32 ml-2"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const pageNumber = Number(jumpToPage);
                    if (pageNumber > 0 && pageNumber <= totalPages && Number.isInteger(pageNumber)) {
                      setCurrentPage(pageNumber);
                      setJumpToPage('');
                    }
                  }
                }}
              />
            </div>
          </div>

          {pharmacies.length === 0 && (
            <div className="mt-4 text-center text-muted-foreground">No pharmacies found.</div>
          )}
        </Section>
      </div>
    </div>
  );
};

export default PharmacyGrouper;
